var CACHE_NAME = 'neo-v1';
var urlsToCache = ['/'];//, '{{FILES_LIST}}'];

self.addEventListener('fetch', function (event: any) {
    if (event.request.url.indexOf('/api/') !== -1) {
        // event.respondWith(fetch(event.request));
        return true;
    }

    const ext = event.request.url.split('.').pop();

    if (['jpg', 'ico', 'png', 'css', 'woff2', 'js', 'svg'].indexOf(ext) == -1) {
        console.log('fetch.first', event.request.url);

        event.respondWith(fetch(event.request)
            .then(res => {
                const parts = res.url.split('.');
                const ext = res.url.split('.').pop() || '';

                if (parts.length > 1 && ext.length > 1 && ext.length < 6) {
                    var responseToCache = res.clone();

                    return caches.open(CACHE_NAME)
                        .then(function (cache) {
                            console.log('CACHE.ADD', event.request.url);
                            return cache.add(event.request);
                        }).then((result) => {
                            console.log('CACHE.ADD.RESULT', result);
                            return res;
                        });

                }
                return res;
            })
            .catch(err => caches.match(event.request).then(function (response) {
                // Cache hit - return response
                if (response) {
                    return response;
                }
                console.warn('cache miss', event.request);
                return caches.match('/');
            }
            )));
    } else {
        event.respondWith(caches.match(event.request)
            .then(function (response) {
                // Cache hit - return response
                if (response) {
                    console.log('found hit');
                    return response;
                }
                console.warn('cache miss', event.request);
                return fetch(event.request)
                    .then(res => {
                        const parts = res.url.split('.');
                        const ext = res.url.split('.').pop() || '';

                        if (parts.length > 1 && ext.length > 1 && ext.length < 6) {
                            var responseToCache = res.clone();

                            return caches.open(CACHE_NAME)
                                .then(function (cache) {
                                    console.log('CACHE.ADD', event.request.url);
                                    return cache.add(event.request);
                                }).then((result) => {
                                    console.log('CACHE.ADD.RESULT', result);
                                    return res;
                                });
                        }
                        return res;
                    })
                    .catch(err => caches.match(event.request).then(function (response) {
                        // Cache hit - return response
                        if (response) {
                            return response;
                        }
                        console.warn('cache miss', event.request);
                        return caches.match('/');
                    }
                    ));

            }));
    }
});

self.addEventListener('install', function (event: any) {
    event.waitUntil(
        (self as any).skipWaiting()
            .then(() => {
                return caches.keys().then(function (cacheNames) {
                    console.log('DELETE PREVIOUS CACHE');
                    return Promise.all(
                        cacheNames.map(function (cacheName) {
                            if (urlsToCache.indexOf(cacheName) === -1) {
                                return caches.delete(cacheName);
                            }
                        })
                    );
                })
            })
            .then(() => {
                return caches.open(CACHE_NAME)
                    .then(function (cache) {
                        console.log('ADD NEW CACHE');
                        return cache.addAll(urlsToCache);
                    })
            })
    );
});
/*

self.addEventListener('activate', function (event: any) {
    event.waitUntil(

    );
});

*/